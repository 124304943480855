<template>
  <div class="Rencai_jl">
    <div class="Rcjl_l">
      <div class="Rcjl_lbanxin">
        <div class="Rcjl_limgbox"><img :src="all.userpic" alt="" /></div>
        <div class="Rcjl_lname">{{ all.realname }}</div>
        <ul class="Rcjl_lbqul">
          <li v-for="(item, index) in userbq" :key="index">{{ item }}</li>
        </ul>
        <div class="Rencai_jltitle">
          <icon-svg icon-class="iconresume" />
          <div>个人资料</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>年龄</div>
          <div>{{ all.age }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>性别</div>
          <div>{{ all.sex == 1 ? "女" : "男" }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>现居</div>
          <div>{{ all.address }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>毕业院校</div>
          <div>{{ all.school }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>专业</div>
          <div>{{ all.major }}</div>
        </div>
        <div class="Rcjl_lziliaoxian"></div>
        <div class="Rencai_jltitle">
          <icon-svg icon-class="icondirection" />
          <div>求职意向</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>求职状态</div>
          <div v-if="all.jobwanted == 1 || all.jobwanted == 0">求职中</div>
          <div v-if="all.jobwanted == 2">在职已提交离职手续</div>
          <div v-if="all.jobwanted == 3">在职未提交离职手续</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>期望职位</div>
          <div>{{ all.uposition }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>期望地址</div>
          <div>{{ all.worksplace }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>期望薪资</div>
          <div>{{ all.workmoney }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>海外经历</div>
          <div>{{ all.overseas == 0 ? "无" : "有" }}</div>
        </div>
        <div class="Rcjl_lziliaoxian"></div>
        <div class="Rencai_jltitle">
          <icon-svg icon-class="iconeducation" />
          <div>教育经历</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>毕业院校</div>
          <div>{{ all.school }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>时间</div>
          <div>{{ all.schooltime_start }} / {{ all.schooltime_end }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>学历</div>
          <div v-if="all.education == 3">专科</div>
          <div v-if="all.education == 4">本科</div>
          <div v-if="all.education == 5">硕士</div>
          <div v-if="all.education == 6">MBA</div>
          <div v-if="all.education == 7">EMBA</div>
          <div v-if="all.education == 8">博士</div>
          <div v-if="all.education == 9">其他</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>学习专业</div>
          <div>{{ all.major }}</div>
        </div>
        <div class="Rcjl_lziliao">
          <div>是否统招</div>
          <div>{{ all.recruit == 1 ? "统招" : "否" }}</div>
        </div>
      </div>
    </div>
    <div class="Rcjl_r">
      <div class="Rencai_jltitle">
        <icon-svg icon-class="iconhuojiangrenyuan" />
        <div>获奖情况</div>
      </div>
      <div class="Rcjl_rjiangall">
        <ul>
          <li v-for="(item, index) in winningall" :key="index">
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="Rencai_jltitle">
        <icon-svg icon-class="iconwork2" />
        <div>作品采用情况</div>
      </div>
      <div class="Rcjl_rjiangall">
        <ul>
          <li v-for="(item, index) in worksall" :key="index">
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="Rencai_jltitle">
        <icon-svg icon-class="iconinternship" />
        <div>工作经验</div>
      </div>
      <ul class="Rcjl_rshixibox">
        <li
          class="Rcjl_rshixiboxli"
          v-for="(item, index) in all.expwork"
          :key="index"
        >
          <div class="Rcjl_rshixiboxlil">{{ index + 1 }}、</div>
          <div class="Rcjl_rshixiboxlir">
            <div class="Rcjl_rshixiboxlirbox">
              <span>公司名称：</span>
              <span>{{ item.gsname }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox">
              <span>实习时间：</span>
              <span>{{ item.gs_start }} / {{ item.gs_end }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox">
              <span>所属行业：</span>
              <span>{{ item.gstype }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox">
              <span>职位名称：</span>
              <span>{{ item.gszhiwei }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox">
              <span>税前工资：</span>
              <span>{{ item.money }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox Rcjl_rshixiboxlirbox_last">
              <span>工作内容：</span>
              <span>{{ item.gstext }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="Rencai_jltitle">
        <icon-svg icon-class="icondata" />
        <div>项目经历</div>
      </div>
      <ul class="Rcjl_rshixibox">
        <li
          class="Rcjl_rshixiboxli"
          v-for="(item, index) in all.userprojectall"
          :key="index"
        >
          <div class="Rcjl_rshixiboxlil">{{ index + 1 }}、</div>
          <div class="Rcjl_rshixiboxlir">
            <div class="Rcjl_rshixiboxlirbox">
              <span>项目名称：</span>
              <span>{{ item.xiangmuname }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox">
              <span>项目时间：</span>
              <span>{{ item.xiangmu_start }} / {{ item.xiangmu_end }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox">
              <span>所属行业：</span>
              <span>{{ item.xiangmuhy }}</span>
            </div>
            <div class="Rcjl_rshixiboxlirbox Rcjl_rshixiboxlirbox_last">
              <span>项目简介：</span>
              <span>{{ item.xiangmutext }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="Rencai_jltitle">
        <icon-svg icon-class="iconself" />
        <div>自我评价</div>
      </div>
      <div class="Rcjl_rjiangall">{{ all.sertextall }}</div>
      <div class="Rencai_jltitle">
        <icon-svg icon-class="iconvip" />
        <div>以下为付费信息</div>
      </div>
      <div v-if="all.payResume == 0">
        <div class="Rcjl_rfufei">
          <p class="Rcjl_rfufeitop">
            <span>￥2.00</span>
            <span @click="buyjl(all.id, all.realname)">立即付费查看</span>
          </p>
        </div>
        <div class="Rcjl_rfufeibox">
          <div class="Rcjl_rfufeibox3">
            <img
              class="Rcjl_rfufeibox3img"
              src="https://task.hozoin.cn/Works/20210105/sj-1176455191.png"
              alt=""
            />
            <p class="Rcjl_rfufeibox3t">联系方式</p>
            <div class="Rcjl_rfufeibox3btn" style="background: #fcfcfc">
              **********
            </div>
          </div>
          <div class="Rcjl_rfufeibox3 Rcjl_rfufeibox3iosb">
            <img
              class="Rcjl_rfufeibox3img"
              src="https://task.hozoin.cn/Works/20210105/sj-7249221076.png"
              alt=""
            />
            <p class="Rcjl_rfufeibox3t">简历</p>
            <div class="Rcjl_rfufeibox3btn">立即下载</div>
          </div>
          <div class="Rcjl_rfufeibox3 Rcjl_rfufeibox3iosb">
            <img
              class="Rcjl_rfufeibox3img"
              src="https://task.hozoin.cn/Works/20210105/sj-3466375136.png"
              alt=""
            />
            <p class="Rcjl_rfufeibox3t">附件</p>
            <div class="Rcjl_rfufeibox3btn">立即下载</div>
          </div>
        </div>
      </div>
      <div v-if="all.payResume == 1">
        <div class="Rcjl_rfufei">
          <p class="Rcjl_rfufeitop">您已付费，可查看以下信息</p>
          <a :href="lianxiall.worksadd" ref="file" download="简历"></a>
        </div>
        <div class="Rcjl_rfufeibox">
          <div class="Rcjl_rfufeibox3">
            <img
              class="Rcjl_rfufeibox3img"
              src="https://task.hozoin.cn/Works/20210105/sj-5758926883.png"
              alt=""
            />
            <p class="Rcjl_rfufeibox3t">联系方式</p>
            <div class="Rcjl_rfufeibox3lianxi">
              <icon-svg icon-class="iconyouxiang" />
              <div>{{ lianxiall.email }}</div>
            </div>
            <div class="Rcjl_rfufeibox3lianxi">
              <icon-svg icon-class="iconshouji54" />
              <div>{{ lianxiall.mobile }}</div>
            </div>
            <div class="Rcjl_rfufeibox3lianxi">
              <icon-svg icon-class="icondianhua-" />
              <div>{{ lianxiall.oicq }}</div>
            </div>
            <div class="Rcjl_rfufeibox3lianxi">
              <icon-svg icon-class="iconweixin" />
              <div>{{ lianxiall.wechat }}</div>
            </div>
          </div>
          <div class="Rcjl_rfufeibox3 Rcjl_rfufeibox3iosb">
            <img
              class="Rcjl_rfufeibox3img"
              src="https://task.hozoin.cn/Works/20210105/sj-0362674164.png"
              alt=""
            />
            <p class="Rcjl_rfufeibox3t">简历</p>
            <div class="Rcjl_rfufeibox3btn Rcjl_rfufeibox3btnred">立即下载</div>
          </div>
          <div class="Rcjl_rfufeibox3 Rcjl_rfufeibox3iosb">
            <img
              class="Rcjl_rfufeibox3img"
              src="https://task.hozoin.cn/Works/20210105/sj-7889427970.png"
              alt=""
            />
            <p class="Rcjl_rfufeibox3t">附件</p>
            <div
              class="Rcjl_rfufeibox3btn Rcjl_rfufeibox3btnred"
              @click="jldown"
            >
              立即下载
            </div>
          </div>
        </div>
      </div>
    </div>
    <zhifu :all="dataall" />
  </div>
</template>
<script>
import zhifu from "../components/zhifu";
export default {
  components: { zhifu },
  data() {
    return {
      dataall: {
        type: false,
        money: "",
        ddnnum: "",
        class_id: "6",
      },
      id: 0,
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      all: {},
      user_info_iostanbqall: [
        {
          text: "规划设计",
          num: "1",
          classred: "",
          ft: "0",
        },
        {
          text: "景观设计",
          num: "2",
          classred: "",
          ft: "0",
        },
        {
          text: "建筑设计",
          num: "3",
          classred: "",
          ft: "0",
        },
        {
          text: "室内设计",
          num: "4",
          classred: "",
          ft: "0",
        },
        {
          text: "软装设计",
          num: "5",
          classred: "",
          ft: "0",
        },
        {
          text: "服装设计",
          num: "6",
          classred: "",
          ft: "0",
        },
        {
          text: "家具设计",
          num: "7",
          classred: "",
          ft: "0",
        },
        {
          text: "交通工具设计",
          num: "8",
          classred: "",
          ft: "0",
        },
        {
          text: "轻工产品设计",
          num: "9",
          classred: "",
          ft: "0",
        },
        {
          text: "机械设备设计",
          num: "10",
          classred: "",
          ft: "0",
        },
        {
          text: "广告设计",
          num: "11",
          classred: "",
          ft: "0",
        },
        {
          text: "包装设计",
          num: "12",
          classred: "",
          ft: "0",
        },
        {
          text: "图书设计",
          num: "13",
          classred: "",
          ft: "0",
        },
        {
          text: "展陈设计",
          num: "14",
          classred: "",
          ft: "0",
        },
        {
          text: "品牌标志设计",
          num: "15",
          classred: "",
          ft: "0",
        },
      ],
      userbq: [],
      userinfo: {},
      winningall: [],
      worksall: [],
      lianxiall: {},
    };
  },
  created() {
    var that = this;
    var id = that.$route.query.data;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    that.id = id;
    this.$axios({
      headers: { "X-CSRF-TOKEN": that.userinfo.token },
      url: "/api/getResumeInfo",
      method: "post",
      data: {
        userid: id,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.all = res.data.result;
        this.fenxiang(
          document.title,
          res.data.result.realname,
          res.data.result.userpic
        );
        if (res.data.result.payResume == 1) {
          this.jlphone(res.data.result.id, res.data.result.userid);
        }
        that.winningall = JSON.parse(res.data.result.winning);
        that.worksall = JSON.parse(res.data.result.works);
        var skillbq = res.data.result.skill.split(",");
        for (var i = 0; i < skillbq.length; i++) {
          for (var s = 0; s < that.user_info_iostanbqall.length; s++) {
            if (skillbq[i] == that.user_info_iostanbqall[s].num) {
              that.userbq.push(that.user_info_iostanbqall[s].text);
            }
          }
        }
      } else {
        console.log("请求失败");
      }
    });
  },
  methods: {
    buyjl(id, title) {
      var that = this;
      if (this.userinfo != null) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/payResume",
          method: "post",
          data: {
            id,
            title,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            that.dataall = {
              type: true,
              money: "2",
              ddnnum: res.data.result,
              class_id: "6",
            };
          } else {
          }
        });
      } else {
        this.$router.push({
          path: "/sjlogin",
        });
      }
    },
    jlphone(id, userid) {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/getResumeLianxi",
        method: "post",
        data: {
          id,
          userid,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.lianxiall = res.data.result;
        } else {
        }
      });
    },
    jldown() {
      this.$refs.file.click();
    },
  },
};
</script>
<style>
@import "../assets/css/rencai.css";
</style>
