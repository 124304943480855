<template>
  <div class="zhifu">
    <el-dialog
      title="支付信息"
      :visible.sync="dialogVisible"
      width="500px"
      top="10%"
      :before-close="handleClose_zj"
    >
      <div class="zhifubox">
        <div class="zhifubox_t">支付金额</div>
        <div class="zhifubox_money">￥{{ all.money }}</div>
        <div class="zhifubox_type" v-if="yuetype">
          <el-radio v-model="radioval" label="1">余额支付</el-radio>
          <span class="zhifubox_typem">￥{{ yuemoney }}</span>
        </div>
        <div class="zhifubox_type">
          <el-radio v-model="radioval" label="2">支付宝支付</el-radio>
          <span class="zhifubox_typem"></span>
        </div>
        <div class="yuezhifu" v-if="yuezhifutype">
          <p>支付密码默认为登陆密码</p>
          <el-input
            v-model="mima"
            show-password
            placeholder="请输入支付密码"
          ></el-input>
          <el-button @click="yuepay">立即支付</el-button>
        </div>
        <div class="zfbzhifu" v-if="zfbzhifutype">
          <div>
            <iframe
              style="width: 100%; height: 30vh; border: none"
              :src="newalli"
              id="alipayWap"
            ></iframe>
          </div>
        </div>
        <div class="zhifubox_btm">
          <div v-if="yuezhifutype">
            <el-checkbox v-model="checkedzhifu"
              >点击确认即视为您同意</el-checkbox
            ><router-link
              target="_Blank"
              class="zhifubox_btm_a"
              to="/help?data=24&big=58"
              >《和作营平台用户协议》</router-link
            >
          </div>
          <div v-if="yuezhifutype">
            <router-link to="/Userhome/Userhl_info">忘记密码</router-link>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["all"],
  data() {
    return {
      checkedzhifu: false,
      dialogVisible: false,
      radioval: "1",
      yuezhifutype: true,
      zfbzhifutype: false,
      newalli: "",
      timer: null,
      yuemoney: 0,
      mima: "",
      //   all:{
      //     type:false,
      //     money:100,
      //   },
      userinfo: {},
      aliurl: "",
      order_codenum: "",
      yuetype: false,
    };
  },
  methods: {
    handleClose_zj() {
      this.dialogVisible = false;
      location.reload();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    order_code() {
      this.newalli = "https://api.hozoin.cn/api/aliPay/" + this.order_codenum;
    },
    yuepay() {
      var that = this;
      var ddnum = this.all.ddnnum;
      if (that.checkedzhifu) {
        that
          .$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/balancePay",
            method: "post",
            data: {
              order_code: ddnum,
              class_id: that.all.class_id, //1 项目 4作品分类 6人才 7教育 10保证金
              paypass: that.mima,
            },
          })
          .then((res) => {
            if (res.data.code == 1) {
              this.$message("支付成功");
              that.dialogVisible = false;
              if (that.all.faxiangmu == 88) {
                this.$router.push({ path: "/Userhome/Userhl_baoming" });
              } else {
                if (that.all.class_id == "7") {
                  //加入教育视频
                  that
                    .$axios({
                      headers: { "X-CSRF-TOKEN": that.userinfo.token },
                      url: "/api/getJoinPoster",
                      method: "post",
                      data: {
                        id: that.all.p_id,
                        userid: that.userinfo.userid,
                      },
                    })
                    .then((res) => {
                      if (res.data.code == 1) {
                        that.$message({
                          message: "加入成功",
                          type: "success",
                        });
                        location.reload();
                      } else {
                        that.$message({
                          message: "不可重复加入",
                        });
                      }
                    });
                } else {
                  location.reload();
                }
              }
            } else {
              this.$message(res.data.messages);
            }
          });
      } else {
        this.$message("请确认同意和作营平台用户协议");
      }
    },
  },
  created() {
    var that = this;
    this.dialogVisible = this.all.type;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.$axios({
      headers: { "X-CSRF-TOKEN": that.userinfo.token },
      url: "/api/getUserInfoData",
      method: "post",
      data: {},
    }).then((res) => {
      if (res.data.code == 1) {
        that.yuemoney = res.data.result.can_money;
      } else {
      }
    });
  },
  watch: {
    radioval() {
      var that = this;
      if (that.radioval == "2") {
        that.yuezhifutype = false;
        that.zfbzhifutype = true;
        var ddnum = this.all.ddnnum;
        this.order_codenum = ddnum;
        that.timer = setInterval(() => {
          that
            .$axios({
              headers: { "X-CSRF-TOKEN": that.userinfo.token },
              url: "/api/findOrderPay",
              method: "post",
              data: {
                order_code: that.order_codenum,
              },
            })
            .then((res) => {
              if (res.data.code == 1) {
                that.$message("支付成功");
                clearInterval(that.timer);
                setTimeout(() => {
                  location.reload();
                }, 1000);
              } else {
                // this.$message("支付失败");
              }
            });
        }, 1000);
      } else {
        clearInterval(that.timer);
        that.zfbzhifutype = false;
        that.yuezhifutype = true;
      }
    },
    all() {
      this.dialogVisible = this.all.type;
      if (this.all.class_id == 10 || this.all.class_id == "") {
        this.radioval = "2";
        this.yuetype = false;
      } else {
        this.yuetype = true;
      }
    },
    order_codenum() {
      this.order_code();
    },
  },
};
</script>
<style>
.zfbzhifu {
  display: flex !important;
  width: 50%;
  margin: 0 auto;
  justify-content: center !important;
}
</style>